import React, { useEffect, useState } from "react";
import { Box, Paper, Typography, Button, CircularProgress, IconButton } from "@mui/material";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import Cookies from "js-cookie";
import { API, SaveQuestionnaire, UpdateJobPost } from "../classes/config/controller";
import { formatDate } from "../classes/GlobalFunctions";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DifferenceIcon from '@mui/icons-material/Difference';
import { ActionButton } from "../components/ActionButton";
import { GenerateDocument } from "../classes/GenerateDocx";
import { v4 as uuidv4 } from 'uuid';
import AddContentModal from "../components/AddContentModal";
import { Form } from "react-bootstrap";
import DownloadDocxIcon from "../components/DownloadDocxIcon";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import DownloadDocxButton from "../components/DownloadDocx";

export const SavedDocuments = ()=>{
const [user, setUser] = useState(Cookies.get('user'))
const [data, setData] = useState([])
const [loading, setLoading] = useState(true)
const [showDocument, setShowDocument] = useState(false)
const [docId, setDocId] = useState('')
const [jobPostData, setJobPostData] = useState()
const [job, setJob] = useState('')
const [dateCreated, setDateCreated] = useState('')
const [update, setUpdate] = useState(false)
const [showDelete, setShowDelete] = useState(false);
const [editorContent, setEditorContent] = useState('');
const [documentTitle, setDocumentTitle] = useState('')

const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

const handleBack = ()=>{

    setShowDocument(false)
    setUpdate(!update)
    }


const editDoc = (content,title, id, dateCreated)=>{
    setEditorContent(content)
    setDocumentTitle(title);
    setDocId(id);
    setDateCreated(dateCreated)
   
    setShowDocument(true)
} 

const deleteJP = async (id)=>{
    const check = window.confirm('Are you sure you would like to do this?');

    if(check){
        
    const resp = await axios.post(API+ 'job-description-genie/delete-jd',{email:user, iqId: id})
    
    if(resp === 'error'){
        window.alert('Something went wrong when loading user data. Please try again.')
    } else {
        console.log(resp.data)
       setUpdate(!update)
     
        setLoading(false)
    }

}
}


const duplicate = async (content)=>{

    const addName = window.prompt('Please enter a different name for this document:');

    if(addName){
    if(addName !== job || addName > 0){
      
        let newObject = {
            id: uuidv4(),
            dateCreated: new Date(),
            jobName: addName,
            content: content
        }
        const resp = await axios.post(API+'job-description-genie/add-jd',{email:user, object: newObject})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }
}
}

const changeDocName = async (id, job)=>{

    const addName = window.prompt('Please enter a different name for this document:');
if(addName){
    

    if(addName !== job || addName > 0){

        
        const resp = await axios.post(API+'job-description-genie/change-jd-name',{email:user, id:id, name: addName})

        if(resp === 'error'){
            window.alert('Something went wrong when loading user data. Please try again.')
        } else {
            console.log(resp.data)
            setUpdate(!update)
         
        }
    }

}
}
useEffect(()=>{

    const getData = async ()=>{
        console.log('running')
            const resp = await axios.post(API+'get-data',{email:user})

            if(resp === 'error'){
                window.alert('Something went wrong when loading user data. Please try again.')
            } else {
                console.log(resp.data)
               
                setData(resp.data[0].jobDescriptions)
             
                setLoading(false)
            }
    }
    getData()
},[update])
if(loading){
    return (
        <div style={{textAlign:'center'}}>
            <CircularProgress />
        </div>
    )
} else {
    if(!showDocument){
        
    return (
        <Paper sx={{padding:1, margin:4}}>
             <Table hover>
      <thead>
        <tr>
        
          <th><Typography variant="button"><b>Job Description Name</b></Typography></th>
          <th><Typography variant="button"><b>Date Created</b></Typography></th>
          <th style={{textAlign:'center'}}><Typography variant="button"><b>Actions</b></Typography></th>
        </tr>
      </thead>
      <tbody>
          {data.map(i=>{
            return(
                <tr key={i.id}>
        
          <td ><span >{i.jobName}</span> <ActionButton OnClick={()=>changeDocName(i.id)} Title="Edit Name" Icon={<EditIcon htmlColor="#0098D9"/>}/></td>
          <td style={{paddingTop:'15px'}}>{formatDate(i.dateCreated)}</td>
          <td style={{textAlign:'center'}}>
          <ActionButton OnClick={()=>editDoc(i.content,i.jobName, i.id, i.dateCreated)} Title="Edit" Icon={<EditIcon htmlColor="#0098D9"/>}/>
          <ActionButton OnClick={()=>duplicate(i.content)} Title="Duplicate" Icon={<DifferenceIcon htmlColor="#0098D9"/>}/>
          <DownloadDocxIcon htmlContent={i.content} docName={i.job}/>
          <ActionButton OnClick={()=>deleteJP(i.id)} Title="Delete" Icon={<DeleteIcon htmlColor="tomato"/>}/>
          </td>
        </tr>
            )
          })}
      </tbody>
    </Table>
        </Paper>
    )

} else {
    return (
        <div>

            <Box sx={{marginBottom:'10px'}}>
            <Button variant="contained" onClick={handleBack}><ArrowBackIosNewIcon/> <b>BACK</b></Button>

            <Button onClick={()=>{
                 let object = {
                    id:docId,
                    dateCreated: dateCreated,
                    jobName:documentTitle,
                    content: editorContent 
                }
                UpdateJobPost(object);
                handleBack()
                
                }} sx={{marginLeft:'10px', float:'right', display:showDelete ? 'none':'inline'}}  variant="contained"><b>SAVE CHANGES</b></Button>
                        <DownloadDocxButton htmlContent={editorContent} docName={documentTitle} />

            
            </Box>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

    <div style={{ height:'100%'}}>
    <CKEditor
        editor={InlineEditor}
     
        data={editorContent}
        onChange={handleEditorChange}
      />
    
              
    </div>
   
    </Paper>
    </div>
    )
}
}
    
}